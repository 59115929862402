.box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: 300px; */
    height: 68px;
    margin: 65px auto;
    border: 1px solid #101010;
    padding: 0 10px;
    border-radius: 4px;
}
  
  button {
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer
  }
  
  button:focus {
    outline: none;
  }
  
  button img {
    width: 25px;
  }

  .ref-section{
    background-color: transparent;
    margin: 20px;
    text-align: center;
  }

  .staking-overflow .tab-content2 {
    padding: 56px 30px;
    box-shadow: 0 4px 25px rgb(51 51 51 / 15%);
    background: aliceblue;
}

.level-span{
    color: #0a202c;
}


footer {
  position: relative;
  padding: 56px 0 25px 0;
  background: rgba(0, 0, 0, 0.15);
}

[class*="__container"] {
  max-width: 1245px;
  width: 100%;
  margin: auto;
  padding: 0 10px;
}

.footer-top {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.footer-middle {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.footer-menus {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 945px;
  width: 100%;
}

.footer-menu > span {
  display: block;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #FFFFFF;
  margin-bottom: 25px;
}

.footer-menu ul {
  list-style: none;
  padding: 0;
}

.footer-menu ul > li:not(:last-child) {
  margin-bottom: 15px;
}

.footer-menu ul > li > a {
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #7F8AA8;
}

.footer-wallet {
  margin: -12px 0 0 auto;
}

.gwallet {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.gwallet > span {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #7F8AA8;
}

.footer-bottom {
  margin-top: 85px;
  padding-top: 23px;
  border-top: 1px solid #171323;
}

.footer-bottom__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.social-list {
  display: flex;
  gap: 20px;
  align-items: center;
}

.soc-lnk {
  text-decoration: none;
}

.copyright {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #7F8AA8;
}

.other-opts {
  display: flex;
  align-items: center;
  gap: 70px;
}

.btn-donate {
  position: relative;
  padding: 0;
  outline: none;
  border: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  background: transparent;
}

.walletbox {
  position: absolute;
  display: none;
}

.btn-donate > span {
  color: #7F8AA8;
}

.burncoinsbox {
  position: relative;
}

.burncoins {
  position: absolute;
  display: flex;
  bottom: calc(100% + 10px);
  background: red;
  left: 50%;
  transform: translate(-50%, 0);
  background: linear-gradient(90deg, #6DBBF5 -1.67%, #AF63C1 101.19%);
  box-shadow: 0px 11px 23px #050409;
  border-radius: 7px;
  padding: 7px 15px;
  gap: 28px;
  opacity: 0;
  visibility: hidden;
  transition: opacity .12s linear, visibility .12s linear;
}

.f-lnk {
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #7F8AA8;
}

@media screen and (max-width: 1180px){
  .footer-middle {
      gap: 60px;
  }
}

@media screen and (max-width: 820px){
  .footer-middle {
      flex-wrap: wrap;
  }

  .footer-menus {
    flex-wrap: wrap;
    gap: 30px 0;
  }

  .footer-menu {
    flex: 0 1 47%;
  }

  .footer-wallet {
    width: 100%;
  }

  .footer-bottom__container {
    flex-direction: column;
    gap: 20px 0;
  }
}


